import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { lineData } from "../@core/data/chart-data/line-data";
import { barData } from "../@core/data/chart-data/bar-data";
import { pieDataItem } from "../@core/data/chart-data/pie-data";
import { radarDataItem } from "../@core/data/chart-data/radar-data";
import { CurrentMonthConversations } from "../@core/data/chart-data/current-month-conversations";

import { Subject, from } from "rxjs";
import { io } from "socket.io-client";
import { EnFrYearMonthsToDate } from "../@core/data/chart-data/en-fr-year-months-to-date";
import { IntentCountMonthToDate } from "../@core/data/chart-data/intent-count-month-to-date";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class WebsocketService {
  private socket;
  constructor(private authService: AuthService) {}

  private websocketUrl = environment.websocketUrl;

  initializeSocket() {
    this.socket = io(this.websocketUrl, {
      extraHeaders: {
        Authorization: this.authService.getToken(),
      },
    });
  }

  destroySocket() {
    this.socket = null;
  }

  getSocket() {
    return this.socket;
  }
}
