<div *ngIf="!editing">
  <div class="user-details">
    <div class="image-container">
      <img [src]="user?.pictureUrl" alt="Profile Picture" />
    </div>
    <button
      class="edit-button"
      [pTooltip]="translate.instant('profile.edit_tooltip')"
      (click)="turnOnOffEdit()"
    >
      <i class="pi pi-pencil"></i>
    </button>
    <div>
      <label>{{'profile.first_name' | translate}}:</label>
      <input type="text" [value]="user.firstName" readonly />
    </div>
    <div>
      <label>{{'profile.last_name' | translate}}:</label>
      <input type="text" [value]="user.lastName" readonly />
    </div>
    <div>
      <label>{{'profile.email' | translate}}:</label>
      <input type="text" [value]="user.email" readonly />
    </div>
    <div>
      <label>{{'profile.contact_info' | translate}}:</label>
      <input type="text" [value]="user.contactInfo" readonly />
    </div>
    <div>
      <label>{{'profile.permission_level' | translate}}:</label>
      <input type="text" [value]="user.permission" readonly />
    </div>
  </div>
</div>

<div *ngIf="editing">
  <div class="user-details">
    <div class="image-container">
      <p-confirmPopup [showTransitionOptions]="'100ms'" [hideTransitionOptions]="'100ms'"></p-confirmPopup>
      <img
        [src]="startEditUserState?.pictureUrl"
        alt="Profile Picture"
        (click)="openUpdateImagePopup($event)"
        class="image-editing"
      />
      <span
        class="edit-icon"
        (click)="openUpdateImagePopup($event)"
        [pTooltip]="translate.instant('profile.change_profile_pic_tooltip')"
        ><i class="fa-solid fa-pencil"></i
      ></span>
      <input
        #imageInput
        type="file"
        (change)="onImageSelected($event)"
        style="display: none"
        accept="image/*"
      />
    </div>
    <div>
      <label>{{'profile.first_name' | translate}}:</label>
      <input type="text" [(ngModel)]="startEditUserState.firstName" />
    </div>
    <div>
      <label>{{'profile.last_name' | translate}}:</label>
      <input type="text" [(ngModel)]="startEditUserState.lastName" />
    </div>
    <div>
      <label>{{'profile.email' | translate}}:</label>
      <input type="text" [(ngModel)]="startEditUserState.email" />
    </div>
    <div>
      <label>{{'profile.contact_info' | translate}}:</label>
      <input type="text" [(ngModel)]="startEditUserState.contactInfo" />
    </div>
    <div>
      <label>{{'profile.permission_level' | translate}}:</label>
      <input type="text" [(ngModel)]="startEditUserState.permission" readonly />
    </div>
    <div class="user-details-footer">
      <button
        pButton
        pRipple
        [disabled]="!hasChanges()"
        [loading]="updatingCurUser"
        type="button"
        icon="pi pi-check"
        [label]="translate.instant('profile.save')"
        class="p-button-sm p-button-raised"
        (click)="saveProfileChanges()"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-times"
        [label]="translate.instant('profile.cancel')"
        class="p-button-sm p-button-raised p-button-danger"
        (click)="cancelProfileChanges()"
      ></button>
    </div>
  </div>
</div>
