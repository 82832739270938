import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { UserService } from "../../../../services/users.service";
import { ConfirmationService } from "primeng/api";
import { profile } from "console";
import { User } from "../../../../@core/data/users";
import {
  Languages,
  LanguagesService,
} from "../../../../services/languages.service";

import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

enum ProfilePicEditStatus {
  Updated,
  Removed,
  Unchanged,
}

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["profile.component.scss"],
  providers: [ConfirmationService],
})
export class ProfileComponent implements OnInit {
  display: boolean = false;
  editing: boolean = false;
  updatingCurUser: boolean = false;
  profilePicEditStatus: ProfilePicEditStatus = ProfilePicEditStatus.Unchanged;
  startEditUserState: User = null;
  user: User;
  curRoute: string;
  newProfilePic: File = null;

  @ViewChild("imageInput", { static: false }) imageInputRef: ElementRef;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.user = this.config.data.user;
    this.userService.user$.subscribe((newUser) => {
      this.user = { ...newUser };
    });
  }

  onImageSelected(event: any): void {
    let file: File = event.target.files[0];
    if (file) {
      this.newProfilePic = file;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.startEditUserState.pictureUrl = e.target.result; // This will update the image source to the selected image.
        this.profilePicEditStatus = ProfilePicEditStatus.Updated;
      };
      reader.readAsDataURL(file);
    }
  }

  openUpdateImagePopup(event) {
    this.confirmationService.confirm({
      target: event.target,
      message: "Upload a new picture or remove your profile picture",
      acceptLabel: "Browse",
      rejectLabel: "Remove",
      icon: "fa-solid fa-image",
      accept: () => {
        this.triggerImageInput();
      },
      reject: () => {
        if (
          this.startEditUserState.picture !=
          "dashboard-users_profile-img/default.png"
        ) {
          this.userService.getDefaultImageUrl().subscribe(
            (url: string) => {
              this.startEditUserState.picture =
                "dashboard-users_profile-img/default.png";
              this.startEditUserState.pictureUrl = url;
              this.profilePicEditStatus = ProfilePicEditStatus.Removed;
            },
            (error) => {
              console.log(error);
            }
          );
        }
      },
    });
  }

  turnOnOffEdit() {
    this.editing = !this.editing;
    if (this.editing) {
      this.startEditUserState = { ...this.user };
    }
  }

  triggerImageInput() {
    this.imageInputRef.nativeElement.click();
  }

  saveProfileChanges() {
    this.updatingCurUser = true;
    // If profile picture was removed, remove it from the bucket
    if (this.profilePicEditStatus === ProfilePicEditStatus.Removed) {
      this.userService
        .removeProfileImage(this.startEditUserState.uid + ".png")
        .subscribe(
          (data) => {
            this.updateCurrentUser();
          },
          (error) => {}
        );
    } else if (this.profilePicEditStatus === ProfilePicEditStatus.Updated) {
      const fileName = this.startEditUserState.uid + ".png";
      this.userService
        .uploadProfileImage(this.newProfilePic, fileName)
        .subscribe((url: string) => {
          this.startEditUserState.pictureUrl = url;
          this.startEditUserState.picture = `dashboard-users_profile-img/${fileName}`;
          this.updateCurrentUser();
        });
    } else {
      this.updateCurrentUser();
    }
  }

  updateCurrentUser() {
    this.userService.updateCurrentUser(this.startEditUserState).subscribe(
      (data) => {
        this.userService.setCurrentUser(this.startEditUserState);
        this.startEditUserState = null;
        this.updatingCurUser = false;
        this.turnOnOffEdit();
        this.finishEdit();
      },
      (error) => {}
    );
  }

  cancelProfileChanges() {
    this.turnOnOffEdit();
  }

  close() {
    this.ref.close();
  }

  hasChanges(): boolean {
    return !this.deepEqual(this.user, this.startEditUserState);
  }

  finishEdit() {
    this.startEditUserState = null;
    this.updatingCurUser = false;
    this.newProfilePic = null;
    this.profilePicEditStatus = ProfilePicEditStatus.Unchanged;
    this.editing = false;
  }

  deepEqual(obj1, obj2) {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  }
}
