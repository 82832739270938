import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  linkWithPopup,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { UserService } from "./users.service";
import { Router } from "@angular/router";
import { take } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private userService: UserService,
    private router: Router,
    private auth: AngularFireAuth
  ) {
    this.auth.onIdTokenChanged(function (user) {
      if (user) {
        // User is signed in or token was refreshed.
        user.getIdToken(true).then((token) => {
          localStorage.setItem("idToken", token);
        });
      }
    });
  }

  login(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  loginWithGoogle() {
    const provider = new GoogleAuthProvider();
    return this.auth.signInWithPopup(provider);
  }

  loginWithMicrosoft() {
    const provider = new OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      // Optional "tenant" parameter in case you are using an Azure AD tenant.
      // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
      // or "common" for tenant-independent tokens.
      // The default value is "common".
      tenant: "13bf7aa5-0b8c-412d-b594-4b66b2a548b8",
    });
    return this.auth.signInWithPopup(provider);
  }

  signOut() {
    this.auth
      .signOut()
      .then(() => {
        this.userService
          .getCurrentUser()
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              this.userService.updateUserStatus(false, res.uid).subscribe({
                next: (res) => {},
                error: (err) => {console.log(err);}
              });
              localStorage.removeItem("User");
              localStorage.removeItem("idToken");
              this.router.navigate(["/auth/logout"]);
            },
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getCurrentUser() {
    return this.auth.currentUser;
  }

  getToken() {
    return localStorage.getItem("idToken");
  }
}
