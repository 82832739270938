import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable } from "rxjs";

export enum Languages {
  English = "en",
  French = "fr",
}

@Injectable({
    providedIn: "root",
})
export class LanguagesService {
  curLanguage: Languages = Languages.English;
  private curLanguageSubject: BehaviorSubject<Languages> = new BehaviorSubject<Languages>(this.curLanguage);
  curLanguage$: Observable<Languages> = this.curLanguageSubject.asObservable();

  constructor(private translate: TranslateService) {
  }

  setLanguage(lang: Languages) {
    this.translate.use(lang);
    this.curLanguage = lang;
    this.curLanguageSubject.next(this.curLanguage);
  }
}
