import { of as observableOf, Observable, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { Contacts, RecentUsers, User } from "../@core/data/users";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable()
export class UserService {
  private curUser: User;
  private userSubject = new BehaviorSubject<User | null>(null);
  user$ = this.userSubject.asObservable();

  private time: Date = new Date();

  constructor(private http: HttpClient) {}

  // Backend baseurl
  private baseUrl = environment.backendUrl;

  getUser(idToken: string): any {
    return this.http.post(`${this.baseUrl}/user/auth/login`, {
      idToken: idToken,
    });
  }

  setCurrentUser(user: User) {
    this.curUser = { ...user };
    this.userSubject.next(this.curUser);
  }

  updateCurrentUser(newUser: User) {
    // Convert to database format
    let data = {
      first_name: newUser.firstName,
      last_name: newUser.lastName,
      email: newUser.email,
      contact_info: newUser.contactInfo,
      picture: newUser.picture,
      permission: newUser.permission,
      uid: newUser.uid,
    };
    // Sends http request to backend
    return this.http.post(`${this.baseUrl}/user/update`, data);
  }

  updateUserStatus(status: boolean, uid: string) {
    // Convert to database format
    let data = {
      active_status: status,
      uid: uid,
    };

    // Sends http request to backend
    return this.http.post(`${this.baseUrl}/user/update`, data);
  }

  private contacts: Contacts[];
  private recentUsers: RecentUsers[];

  getContacts(): Observable<Contacts[]> {
    return observableOf(this.contacts);
  }

  getRecentUsers(): Observable<RecentUsers[]> {
    return observableOf(this.recentUsers);
  }

  getCurrentUser(): Observable<User> {
    return observableOf(this.curUser);
  }

  getStoredUser() {
    let data = localStorage.getItem("User");
    if (data) {
      let user: User = JSON.parse(data);
      return user;
    } else {
      return null;
    }
  }

  getDefaultImageUrl() {
    return this.http.get(`${this.baseUrl}/user/image/default`);
  }

  uploadProfileImage(file: File, fileName: string) {
    const formData: FormData = new FormData();
    formData.append("profileImage", file, fileName);

    return this.http.post<string>(
      `${this.baseUrl}/user/image/update`,
      formData,
      { responseType: "text" as "json" }
    );
  }

  removeProfileImage(fileName) {
    return this.http.post(`${this.baseUrl}/user/image/remove`, {
      fileName: fileName,
    });
  }

  sendHeartbeat(uid) {
    return this.http.post(`${this.baseUrl}/user/heartbeat`, { uid: uid });
  }
}
