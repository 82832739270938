import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { lineData } from "../@core/data/chart-data/line-data";
import { barData } from "../@core/data/chart-data/bar-data";
import { pieDataItem } from "../@core/data/chart-data/pie-data";
import { radarDataItem } from "../@core/data/chart-data/radar-data";
import { CurrentMonthConversations } from "../@core/data/chart-data/current-month-conversations";

import { BehaviorSubject, Observable, Subject, from } from "rxjs";
import { io } from "socket.io-client";
import { EnFrYearMonthsToDate } from "../@core/data/chart-data/en-fr-year-months-to-date";
import { IntentCountMonthToDate } from "../@core/data/chart-data/intent-count-month-to-date";
import { environment } from "../../environments/environment";
import { UserService } from "./users.service";

@Injectable({
  providedIn: "root",
})
export class UpdateDataService {
  private baseUrl = environment.backendUrl;

  private curFilterSubject: BehaviorSubject<any> = new BehaviorSubject<any>({
    "selectedOption": "Monthly"
  });
  curFilter$: Observable<any> = this.curFilterSubject.asObservable();

  constructor(private httpClient: HttpClient) {}

  changeFilter(filter) {
    this.curFilterSubject.next(filter);
  }

  getIntentCountMonthly(botID: string, environmentID: string) {
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/intent-count/${botID}/${environmentID}`);
    return data;
  }

  getIntentCountCurrentMonth(botID: string, environmentID: string) {
    // new Data().getMonth() ranges from 0-11 so need to add 1
    const month = new Date().getMonth() + 1;
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/intent-count/${botID}/${environmentID}?month=${month}`);
    return data;
  }

  getIntentCountDaily(botID: string, environmentID: string, day) {
    let month = day.getMonth() + 1;
    let year = day.getFullYear();
    let date = day.getDate();
    let filterDay = year + '-' + month + '-' + date
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/intent-count/${botID}/${environmentID}?day=${filterDay}`);
    return data;
  }

  getIntentCountCustomDate(botID: string, environmentID: string, start, end) {
    let startMonth = start.getMonth() + 1;
    let startYear = start.getFullYear();
    let startDate = start.getDate();
    let startRange = startYear + '-' + startMonth + '-' + startDate

    let endMonth = end.getMonth() + 1;
    let endYear = end.getFullYear();
    let endDate = end.getDate();
    let endRange = endYear + '-' + endMonth + '-' + endDate

    let data = this.httpClient.get<any>(`${this.baseUrl}/data/intent-count/${botID}/${environmentID}?start=` + startRange + '&end=' + endRange);
    return data;
  }

  getConversationsMonthly(botID: string, environmentID: string) {
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/conversation-count/${botID}/${environmentID}`);
    return data;
  }

  getConversationsCurrentMonth(botID: string, environmentID: string) {
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/conversation-count/${botID}/${environmentID}?month=true`);
    return data;
  }

  getConversationsDaily(botID: string, environmentID: string, day) {
    let month = day.getMonth() + 1;
    let year = day.getFullYear();
    let date = day.getDate();
    let filterDay = year + '-' + month + '-' + date
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/conversation-count/${botID}/${environmentID}?day=${filterDay}`);
    return data;
  }

  getConversationsCustomDate(botID: string, environmentID: string, start, end) {
    let startMonth = start.getMonth() + 1;
    let startYear = start.getFullYear();
    let startDate = start.getDate();
    let startRange = startYear + '-' + startMonth + '-' + startDate

    let endMonth = end.getMonth() + 1;
    let endYear = end.getFullYear();
    let endDate = end.getDate();
    let endRange = endYear + '-' + endMonth + '-' + endDate
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/conversation-count/${botID}/${environmentID}?start=${startRange}&end=${endRange}`);
    return data;
  }

  getContainmentMonthly(botID: string, environmentID: string) {
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/containment-series/${botID}/${environmentID}`);
    return data;
  }

  getContainmentCurrentMonth(botID: string, environmentID: string) {
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/containment/${botID}/${environmentID}?month=true`);
    return data;
  }

  getContainmentDaily(botID: string, environmentID: string, day) {
    let month = day.getMonth() + 1;
    let year = day.getFullYear();
    let date = day.getDate();
    let filterDay = year + '-' + month + '-' + date
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/containment/${botID}/${environmentID}?day=${filterDay}`);
    return data;
  }

  getContainmentCustomDate(botID: string, environmentID: string, start, end) {
    let startMonth = start.getMonth() + 1;
    let startYear = start.getFullYear();
    let startDate = start.getDate();
    let startRange = startYear + '-' + startMonth + '-' + startDate

    let endMonth = end.getMonth() + 1;
    let endYear = end.getFullYear();
    let endDate = end.getDate();
    let endRange = endYear + '-' + endMonth + '-' + endDate
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/containment/${botID}/${environmentID}?start=${startRange}&end=${endRange}`);
    return data;
  }

  getFailedIntents() {
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/failed-intents`);
    return data;
  }

  getSankeyData(botID, environmentID: string) {
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/sankey/${botID}`);
    return data;
  }

  getSankeyCurrentMonth(botID, environmentID: string) {
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/sankey/${botID}/${environmentID}?month=true`);
    return data;
  }

  getSankeyDaily(botID: string, environmentID: string, date) {
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/sankey/${botID}/${environmentID}?day=${date}`);
    return data;
  }

  getSankeyCustomDate(botID: string, environmentID: string, start, end) {
    let startMonth = start.getMonth() + 1;
    let startYear = start.getFullYear();
    let startDate = start.getDate();
    let startRange = startYear + '-' + startMonth + '-' + startDate

    let endMonth = end.getMonth() + 1;
    let endYear = end.getFullYear();
    let endDate = end.getDate();
    let endRange = endYear + '-' + endMonth + '-' + endDate
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/sankey/${botID}/${environmentID}?start=${startRange}&end=${endRange}`);
    return data;
  }

  getSankeyTable(botID, environmentID) {
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/sankey-table/${botID}/${environmentID}`);
    return data;
  }

  getTreeData(botID, environmentID) {
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/tree/${botID}/${environmentID}`);
    return data;
  }

  getTreeCurrentMonth(botID, environmentID) {
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/tree/${botID}/${environmentID}?month=true`);
    return data;
  }

  getTreeDaily(botID, environmentID, day) {
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/tree/${botID}/${environmentID}?day=${day}`);
    return data;
  }

  getTreeCustomDate(botID, environmentID, start, end) {
    let startMonth = start.getMonth() + 1;
    let startYear = start.getFullYear();
    let startDate = start.getDate();
    let startRange = startYear + '-' + startMonth + '-' + startDate

    let endMonth = end.getMonth() + 1;
    let endYear = end.getFullYear();
    let endDate = end.getDate();
    let endRange = endYear + '-' + endMonth + '-' + endDate
    let data = this.httpClient.get<any>(`${this.baseUrl}/data/tree/${botID}/${environmentID}?start=${startRange}&end=${endRange}`);
    return data;
  }

  updateData() {
    return this.httpClient.get<any>(`${this.baseUrl}/data/update`);
  }

  updateSuggestedIntents() {
    return this.httpClient.get<any>(`${this.baseUrl}/intentminer/run`);
  }

}