<div class="header-all">
  <div class="header-container">
    <div class="logo-container">
      <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
        <nb-icon icon="menu-2-outline"></nb-icon>
      </a>
      <a class="logo" href="#" (click)="navigateHome()"
        ><img
          class="main-logo"
          src="assets/images/VA-HUB.png"
          alt="Main logo"
        />VA HUB</a
      >
    </div>
  </div>

  <div class="header-container">
    <div *ngIf="selectedBot">
      Select a bot:
      <p-dropdown
        [options]="botList"
        [(ngModel)]="selectedBot"
        optionLabel="name"
        [placeholder]="selectedBot != null?selectedBot.name:''"
        [disabled]="fileUploadProcessing || webpageCrawlProcessing"
        (onChange)="processBotChangeEvent($event)"
      ></p-dropdown>
    </div>
    <nb-actions size="small">
      <nb-action>
        <button
          class="p-button-rounded p-button-secondary p-button-text"
          type="button"
          pButton
          icon="fa fa-solid fa-globe"
          (click)="menu.toggle($event)"
        ></button>
      </nb-action>
      <nb-action class="user-action" *nbIsGranted="['view', 'user']">
        <nb-user
          [nbContextMenu]="userMenu"
          [onlyPicture]="userPictureOnly"
          [name]="user?.firstName + ' ' + user?.lastName"
          [picture]="user?.pictureUrl"
        >
        </nb-user>
      </nb-action>
    </nb-actions>
  </div>
</div>

<p-menu #menu [popup]="true" [model]="languageMenuItems"> </p-menu>
