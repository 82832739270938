import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delay, shareReplay, debounceTime } from 'rxjs/operators';

@Injectable()
export class MiscService {
    constructor() {
    }

    epochToReadableDate(epoch: number): string {
        const date = new Date(epoch);
        return date.toLocaleString();
    }
}
