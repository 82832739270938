export const environment = {
  projectID: "connex-telecommunications-inc",
  firebaseConfig: {
    projectId: "connex-telecommunications-inc",
    appId: "1:44145267707:web:e1b9d145b07b7c1bb9f15e",
    storageBucket: "connex-telecommunications-inc.appspot.com",
    locationId: "us-central",
    apiKey: "AIzaSyA7liramUkzh8NdHsDo9sNGSQKpyBN7S6Y",
    authDomain: "connex-telecommunications-inc.firebaseapp.com",
    messagingSenderId: "44145267707",
    measurementId: "G-YLREBWCQYC",
  },
  backendUrl: "https://va-hub-gateway-v1-0-4-ka2yakb.ue.gateway.dev",
  websocketUrl: "https://websocket-proxy-f6h7j3jlja-pd.a.run.app",
  socketIOConnectionUrl: "https://agent-chat-backend-dzg6tttmya-pd.a.run.app",
  webscraperUrl: "https://northamerica-northeast1-connex-telecommunications-inc.cloudfunctions.net/webScraper",
  webscraperAssetsBucket: "connex_website_scraper_assets",
  widgetPath: "../../../assets/widget/connex/index.html",
  widgetScript: `<iframe _ngcontent-rsr-c439="" id="chatBot" src="https://storage.googleapis.com/widget-44145267707/index.html" frameborder="0" scrolling="no" style="position: fixed; right: 0px; bottom: 0px; width: 475px; height: 850px; padding: 5px; z-index: 999999;"></iframe>`,
  dialogflowBotPath:"https://storage.googleapis.com/widget-44145267707/index.html",
  botConfigBucket: "connex-vahub-chatwidget"
};
